import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import MainLayout from '../views/layouts/MainLayout';
import LocalizedLink from '../views/components/LocalizedLink';
import Parallax from '../views/components/Parallax';
import SEO from '../views/components/SEO';

import './404.styl';

const NotFoundPage = ({
    pageContext: { locale, originalPath },
    location,
    data,
}) => {
    if (locale === undefined) {
        if (typeof window !== `undefined`) {
            locale =
                sessionStorage.getItem('lang') !== null
                    ? sessionStorage.getItem('lang')
                    : 'fr';
        } else {
            locale = 'fr';
        }
    }
    return (
        <MainLayout
            locale={locale}
            originalPath={originalPath}
            location={location}
            is404
        >
            <SEO
                title='pages.p404.headerTitle'
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
                is404
            />
            <div className='page-bg p404'>
                <Parallax
                    img={data.coverImage.childImageSharp.fluid}
                    alt='image-carterie-groupe-editor'
                    overlay='light-bottom-left'
                >
                    <div className='page-intro gallery'>
                        <div className='container'>
                            <h1>
                                <FormattedMessage id='pages.p404.title' />
                            </h1>
                            <p>
                                <FormattedMessage id='pages.p404.description' />
                            </p>
                            <LocalizedLink to='/'>
                                <FormattedMessage id='pages.p404.link' />
                            </LocalizedLink>
                        </div>
                    </div>
                </Parallax>
            </div>
        </MainLayout>
    );
};

NotFoundPage.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string,
        originalPath: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default NotFoundPage;

export const query = graphql`
    query {
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "contact.jpg" }
        ) {
            ...BgImages
        }
        seoImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "contact.jpg" }
        ) {
            ...SeoImages
        }
    }
`;
